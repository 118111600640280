<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    />
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        {{ data.value }}
      </template>

      <template #cell(member)="data">
        <div
          class="d-flex"
          style="width: 100px"
        >
          <span>
            {{ data.item.member_type }}
          </span>
          <b-link
            v-if="data.item.member_type === 'ADV'"
            :to="{ name: 'member-adv-view', params: { idx: data.item.member_idx } }"
            class="font-weight-bold d-block text-nowrap"
          >
            ({{ data.item.member_idx }})
          </b-link>
          <b-link
            v-else-if="data.item.member_type === 'INF'"
            :to="{ name: 'member-adv-view', params: { idx: data.item.member_idx } }"
            class="font-weight-bold d-block text-nowrap"
          >
            ({{ data.item.member_idx }})
          </b-link>
        </div>
      </template>

      <template #cell(name)="data">
        {{ data.item.member_info.name }}
      </template>

      <template #cell(amount+vat)="data">
        {{ data.item.amount + data.item.vat }}
      </template>

      <template #cell(created_at)="data">
        <div style="max-width: 90px;">
          {{ resolveDatetime(data.value) }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'

export default {
  components: {
    BLink,
    BButton,
    BSpinner,
    TagbyFilter,
    TagbyList,
  },
  setup() {
    const resolveInfIdx = item => item.influencer?.idx
    const resolveSsnName = item => item.influencer?.ssn?.name
    const resolveSsn = item => item.influencer?.ssn?.ssn
    const resolveDeposit = item => item.deposit ?? 0
    const resolveDatetime = value => (moment(value).isValid() ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null)

    return {
      resolveInfIdx,
      resolveSsnName,
      resolveSsn,
      resolveDeposit,
      resolveDatetime,
      ...viewModel(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
