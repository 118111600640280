import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // 사용자 결제 리스트 받기
    fetchItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/pay/list/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
