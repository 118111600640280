import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-pay-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const initialSearchParams = ref({})
  const currentPage = ref(parseInt(router.history.current.query.page, 10) || 1)
  const searchParams = computed(() => {
    const routerQuery = router.history.current.query
    return {
      // page: parseInt(routerQuery.page, 10) || 1,
      page: currentPage.value,
      size: parseInt(routerQuery.size, 10) || 20,
      total: parseInt(routerQuery.total, 10) || 0,
      ...initialSearchParams.value,
    }
  })
  const syncronizeParamsWithUrl = () => {
    const routerQuery = router.history.current.query
    initialSearchParams.value = Object
      .keys(routerQuery)
      .reduce((previoutValue, currentValue) => {
        if (['page', 'size', 'total'].includes(currentValue)) {
          return previoutValue
        }
        if (currentValue === null || currentValue === '') {
          return previoutValue
        }
        if (typeof routerQuery[currentValue] === 'string') {
          return {
            ...previoutValue,
            [currentValue]: [routerQuery[currentValue]],
          }
        }
        return {
          ...previoutValue,
          [currentValue]: routerQuery[currentValue],
        }
      },
      {})
  }

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: '결제사유',
      value: 'target_type',
      paramName: 'target_type',
      format: 'checkbox',
      options: [
        { text: 'SUBS', value: 'SUBS' },
        { text: 'REWARD', value: 'REWARD' },
        { text: 'ORDER', value: 'ORDER' },
        { text: 'CHARGE', value: 'CHARGE' },
      ],
    },
    {
      label: '결제수단',
      value: 'pay_type',
      paramName: 'pay_type',
      format: 'checkbox',
      options: [
        { text: 'CARD', value: 'CARD' },
        { text: 'NICEPAY_AUTH', value: 'NICEPAY_AUTH' },
        { text: 'ACCOUNT', value: 'ACCOUNT' },
        { text: 'POINT', value: 'POINT' },
      ],
    },
    {
      label: '상태',
      value: 'state',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'SUCCESS', value: 'SUCCESS' },
        { text: 'CANCEL', value: 'CANCEL' },
        { text: 'REGISTER', value: 'REGISTER' },
        { text: 'FAIl', value: 'FAIl' },
        { text: 'CANCELING', value: 'CANCELING' },
        { text: 'WITHDRAW', value: 'WITHDRAW' },
      ],
    },
    {
      label: '결제일자',
      value: 'created_at',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'member', label: 'member' },
    { key: 'name', label: 'name' },
    { key: 'target_type', label: '결제사유' },
    { key: 'description', label: '내용' },
    { key: 'amount+vat', label: '금액' },
    { key: 'pay_type', label: '결제수단' },
    { key: 'state', label: '상태' },
    { key: 'created_at', label: '결제일자' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const shortcutSearch = params => {
    initialSearchParams.value = {
      ...params,
    }
    router.replace({
      query: { ...searchParams.value, page: 1, size: 20 },
    }).catch(() => {})
    fetchItems()
  }

  const search = params => {
    initialSearchParams.value = {
      ...initialSearchParams.value,
      ...params,
    }
    currentPage.value = 1
    router.replace({
      query: { ...searchParams.value, page: currentPage.value },
    }).catch(() => {})
    fetchItems()
  }

  const changePage = params => {
    currentPage.value = params.page
    router.replace({
      query: { ...searchParams.value, ...params },
    }).catch(() => {})
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    syncronizeParamsWithUrl()
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const isDownloadingExcelPoint = ref(false)
  const downloadExcelPoint = () => {
    isDownloadingExcelPoint.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/excelPoint`, searchParams.value)
      .then(() => {
        makeToast('primary', '엑셀 변환 완료', '이메일을 확인하십시오. 몇 분의 시간이 소요될 수 있습니다.')
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        isDownloadingExcelPoint.value = false
      })
  }

  const downloadExcelChangePoint = () => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/excelChangePoint`, searchParams.value)
      .then(response => {
        const fileName = `point_info_${moment().format('YYYYMMDDHHmmss')}.xlsx`
        const file = new File([response.data], fileName, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(file)
        downloadFile(url, fileName)
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        fetchItems()
      })
  }

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    downloadExcelPoint,
    shortcutSearch,
    downloadExcelChangePoint,

    isDownloadingExcelPoint,
  }
}
